* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bgLogin {
  height: 500px;
  background: #00d2ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff),
    url(/src/img/img1.jpg); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3a7bd5, #00d2ff),
    url(/src/img/img1.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}



.wFormLogin {
  width: 85%;

}
.bgFormuLogin {
  background-image: url("../../../img/fondosFormularios/fondoAzulCelular.png");
  background-size: cover;
  border-radius: 10%;
}
.bgNavAdmin {
  background-image: url("../../../img/fondoAzulC.png");
  width: 280px;
  padding-bottom: 50px;
}
.bgFormAdmin {
  background-image: url("../../../img/fondoAzulC.png");
  width: 40%;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 7%;
}

.bgPaginaLogin {
  background-image: url("../../../img/fondoMenuLogin.jpg");
  background-size: contain;
}
.bgPaginaPrincipalAdmin {
  background-image: url("../../../img/fondoMenuLogin.jpg");
  background-size: contain;
  height: 550px;
}

.bgTablaUsuarios {
  background-color: #fafcffa9;
}

.textAzul {
  color: #0071c5;
}
.bgAzul {
  background-color: #0071c5;
}

.bgtitulosad {
  background-color: rgba(255, 255, 255, 0.644);
}
