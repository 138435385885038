.wLogoFooter {
  width: 220px;
}
.logoSSN {
  width: 100%;
}
.msRedes {
  margin-left: 10px;
}

@media all and (max-width: 1023px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .wLogoFooter {
    width: 189px;
  }
  .fsSSN {
    font-size: 13px;
  }
}
@media all and (min-width: 300px) and (max-width: 767px) {
  .imgCenter {
    text-align: center;
  }
  .wLogoFooter {
    width: 189px;
  }
  .m-footer {
    margin-top: 18px;
    text-align: center;
  }
  .redes {
    text-align: center;
  }
  .fsSSN {
    font-size: 13px;
  }
  .logoSSN {
    width: 220px;
  }
  .copyOCS {
    font-size: 10px;
  }
}
