* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap");

.fuenteOpenSans {
  font-family: "Open Sans", sans-serif;
}
.fuenteMontserrat {
  font-family: "Montserrat", sans-serif;
}
.-fuenteNoto {
  font-family: "Open Sans", sans-serif;
}
.colorFuenteAzul {
  color: #1753aa;
}
.fwMontserrat {
  font-weight: 600;
}
.contenedorProducto {
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedorProducto div {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
}

.contenedorProducto div img {
  width: 100%;
  height: 100%;
  transition: all 300ms ease-out;
}

.contenedorProducto div .capa {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(229, 232, 235, 0.884);
  transition: all 300ms ease-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contenedorProducto div:hover > .capa {
  opacity: 1;
  visibility: visible;
}

.contenedorProducto div:hover > .capa h3 {
  margin-top: 50px;
  margin-bottom: 15px;
}
.contenedorProducto div .capa h3 {
  color: #3e69aa;
  font-weight: 700;
  font-size: 130%;
  transition: all 250ms ease-out;
}

.seccionContacto {
  background-image: url("../../img/fondosFormularios/fondoAzulTablet.png");
  background-size: cover;
}
.bgNavLogin {
  background-color: rgb(0, 113, 197);
  background-size: cover;
  height: 35px;
}

.buttonLogin {
  font-size: 15px;
  padding: 5px;
  margin: 5px;
  color: white;
  background-color: rgba(15, 37, 110, 0.37);
}

.userIcon {
  color: white;
  font-size: 3px;
  padding-right: 2px;
}

.divProducto {
  width: 120px;
}

.wIconoPruducto {
  width: 160px;
}
.wGruposIconos {
  width: 48%;
}

.hfondoAzul {
  min-height: 550px;
}

.bordeError404 {
  width: 14px;
  height: 30px;
  background-color: rgb(0, 113, 197);
}

/* css efecto icono acceso productores */

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 1px;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 17rem;
  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin-right: 50px;
  width: 3rem;
  height: 3rem;
  background: rgba(15, 37, 110, 0.37);
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: rgba(243, 243, 243, 0.925);
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 90%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}

.sliderTextA {
  font-size: 34px;
  font-weight: 800;
}
.sliderTextB {
  font-size: 58px;
}
.bgSlider {
  background-color: rgba(0, 0, 0, 0.212);
  border-radius: 5%;
  margin-top: 10%;
  margin-left: 5%;
  margin-bottom: 3.5%;
  margin-right: 13%;
}

.carousel-caption {
  text-align: initial;
  padding-bottom: 5%;
}

.sliderBarra {
  height: 7px;
  width: 250px;
  background-color: rgba(0, 112, 197, 0.658);
  margin-bottom: 20px;
}

.sliderButton {
  background-color: rgb(0, 113, 197);
  width: 150px;
  height: 49px;
  font-size: 18px;
}

.fadeIN {
  animation-delay: 1s;
  animation: fadeIn 1.5s;
}

.mContacto {
  max-width: 600px;
}

.fondoNO {
  width: 100%;
}

.iconNO {
  width: 20px;
  color: rgb(0, 113, 197);
}

.bordeSeccionNuestrasO {
  width: 15px;
  height: 94%;
  background-color: rgb(0, 113, 197);
}

.divFondoSlider {
  background-color: black;
}

.sliceDerecha {
  -webkit-animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.sliceIzquierda {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hPrincipalEmpresas {
  height: 150x;
  padding-bottom: 40px;
}

.w60 {
  width: 200px;
}
.wFormContacto {
  width: 90%;
}

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media all and (min-width: 992px) {
  .contenedorProducto {
    width: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wIconoPruducto {
    width: 75%;
  }
  .wGruposIconos {
    width: 58%;
  }
  .hfondoAzul {
    min-height: 570px;
    max-height: 600px;
  }
  .sliderTextA {
    font-size: 30px;
  }
  .sliderBarra {
    height: 5px;
    width: 250px;
    background-color: rgba(0, 112, 197, 0.658);
    margin-bottom: 20px;
  }
  .sliderTextB {
    font-size: 41px;
  }
  .sliderButton {
    background-color: rgb(0, 113, 197);
    width: 132px;
    height: 34px;
    font-size: 15px;
  }
  .bgSlider {
    background-color: rgba(0, 0, 0, 0.212);
    border-radius: 5%;
    margin-top: 10%;
    margin-left: 5%;
    margin-bottom: 1.5%;
    margin-right: 13%;
  }
}

@media all and (min-width: 501px) and (max-width: 991px) {
  .contenedorProducto {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contenedorProducto div:hover > .capa h3 {
    margin-top: 45px;
    margin-bottom: 15px;
  }
  .contenedorProducto div .capa h3 {
    color: #3e69aa;
    font-weight: 700;
    font-size: 148%;
    transition: all 250ms ease-out;
  }
  .wIconoPruducto {
    width: 70%;
  }
  .wGruposIconos {
    width: 480px;
  }

  .hfondoAzul {
    min-height: 930px;
    max-height: 930px;
  }
  .sliderTextA {
    font-size: 25px;
    text-shadow: grey 2px 1px;
  }
  .sliderBarra {
    height: 5px;
    width: 208px;
    background-color: rgba(0, 112, 197, 0.658);
    margin-bottom: 20px;
  }
  .sliderTextB {
    font-size: 35px;
    text-shadow: grey 2px 1px;
  }
  .sliderButton {
    background-color: rgb(0, 113, 197);
    width: 132px;
    height: 34px;
    font-size: 15px;
  }

  .hImagen {
    min-height: 450px;
    min-width: 914px;
  }

  .h1Contactos {
    font-size: 21px;
  }
}

@media all and (min-width: 300px) and (max-width: 500px) {
  .contenedorProducto {
    width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contenedorProducto div:hover > .capa h3 {
    margin-top: 38px;
    margin-bottom: 15px;
  }
  .wIconoPruducto {
    width: 80%;
  }
  .wGruposIconos {
    width: 260px;
  }

  .hfondoAzul {
    min-height: 770px;
  }
  .sliderTextA {
    font-size: 22px;
    text-shadow: grey 1px 1px;
  }
  .sliderBarra {
    height: 5px;
    width: 208px;
    background-color: rgba(0, 112, 197, 0.658);
    margin-bottom: 20px;
  }
  .sliderTextB {
    font-size: 28px;
    text-shadow: grey 1px 1px;
  }
  .sliderButton {
    background-color: rgb(0, 113, 197);
    width: 132px;
    height: 34px;
    font-size: 15px;
  }

  .hImagen {
    min-height: 500px;
    min-width: 1054px;
  }

  .h1Contactos {
    font-size: 21px;
  }

  .bgSlider {
    background-color: rgba(0, 0, 0, 0.212);
    border-radius: 5%;
    margin-top: 5%;
    margin-left: 0%;
    margin-bottom: 0.5%;
    margin-right: 0%;
  }
  .w60 {
    width: 90%;
  }
}
