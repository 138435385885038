@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@600&family=Raleway:wght@600;700;800&display=swap");

.fuenteNav {
  font-family: "Familjen Grotesk", sans-serif;
  font-family: "Raleway", sans-serif;
}

.w-logo {
  width: 230px;
}
.wRedes {
  width: 30px;
}

.sul .sli {
  list-style: none;
}
.sul .sli a {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 0;
  padding: 8px 8px;
  text-decoration: none;
  color: #4e4b4b;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 200;
  transition: 0.5s;
  z-index: 1;
}
.sul .sli a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  border-top: 4px solid rgb(0, 113, 197);
  border-bottom: 2.5px solid rgb(0, 113, 197);
  border-image: linear-gradient(to right, rgb(0, 113, 197), white) 1;
  transform: scaleY(2.5);
  opacity: 0;
  transition: 0.3s;
  color: #4e4b4b;
}
.sul .sli a:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  border-radius: 30%;
  opacity: 0;
  transition: 0.3s;
  z-index: -1;
}

.sul .sli a:hover:before {
  transform: scaleY(1);
  opacity: 1;
 
}
.sul .sli a:hover:after {
  transform: scaleY(1);
  opacity: 1;

}

.textNav{
  color: #4e4b4b;

}




@media all and (max-width: 1023px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 300px) and (max-width: 767px) {
  .w-logo {
    width: 190px;
  }
}
