* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wImgDos {
  min-height: 370px;
}
.bgTextSm {
  background-color: rgba(0, 0, 0, 0.151);
}

.tituloProducto {
  font-size: 48px;
  padding-top: 40px;
  padding-left: 190px;
}

.subtituloProducto {
  padding-left: 220px;
  padding-top: 10px;
  font-size: 27px;
  padding-right: 20px;
}
.tituloProductoMd {
  font-size: 38px;
  padding-top: 30px;
}

.subtituloProductoMd {
  padding-top: 10px;
  font-size: 24px;
  padding-left: 25px;
  padding-right: 25px;
}
.tituloProductoDos {
  font-size: 48px;
  padding-top: 24px;
  padding-left: 25px;
}

.subtituloProductoDos {
  padding-left: 25px;
  padding-top: 5px;
  font-size: 24px;
  padding-right: 70%;
}
.tituloProductoDosMd {
  margin-top: 15px;
  font-size: 45px;
}
.subtituloProductoDosMd {
  font-size: 22px;
}

.wWhatsapp {
  width: 18px;
}

.borderAcordion {
  border: none;
}
.poligonoProducto {
  background-color: rgb(0, 113, 197);
  /* clip-path: polygon(8% 0, 81% 0, 100% 100%, 30% 100%); */
  clip-path: polygon(10% 0, 104% 0, 106% 84%, 41% 84%);
  width: 50%;
  z-index: 2;
}
.poligonoProductoDos {
  background-color: rgb(rgba(255, 255, 255, 0.76));
  /* clip-path: polygon(67% 0, 97% 0, 84% 100%, 51% 100%); */
  clip-path: polygon(45% 0, 81% 0, 108% 104%, 64% 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}
.poligonoProductoTres {
  background-color: rgb(0, 113, 197);
  /* clip-path: polygon(8% 0, 81% 0, 100% 100%, 30% 100%); */
  clip-path: polygon(0 0, 45% 0, 30% 81%, 0 81%);

  width: 100%;
  z-index: 2;
}
.poligonoProductoCuatro {
  background-color: rgb(rgba(255, 255, 255, 0.76));
  /* clip-path: polygon(67% 0, 97% 0, 84% 100%, 51% 100%); */

  clip-path: polygon(0 0, 54% 0, 37% 100%, 0% 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.bgFormProd {
  background-image: url("../../../img/fondoAzulC.png");
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 3%;
}
.bgFormProd2 {
  background: rgb(0, 113, 197);
  background: linear-gradient(
    90deg,
    rgba(0, 113, 197, 1) 0%,
    rgba(0, 113, 197, 1) 23%,
    rgba(0, 113, 197, 1) 100%
  );
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 3%;
}

.coloChek {
  color: rgb(0, 113, 197);
  font-size: 20px;
}
.coloChekRight {
  color: rgb(0, 113, 197);
  font-size: 12px;
}

.fuenteAzul {
  color: rgb(0, 113, 197);
}
.fuenteIconosCaract {
  color: rgb(22, 58, 85);
  font-size: 20px;
}

.divPasos {
  border: 1px solid black;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.fsTablaAuto {
  font-size: 14px;
}

.logoTabla {
  width: 100px;
}

.wIconosCaract {
  width: 125px;
}

@media all and (min-width: 992px) {
}

@media all and (min-width: 501px) and (max-width: 991px) {
  .wIconosCaract {
    width: 100px;
  }
}

@media all and (min-width: 300px) and (max-width: 500px) {
  .wIconosCaract {
    width: 100px;
  }
}
